@font-face{
    font-family: 'olten';
    src: url('./fontlist/Olten/Olten.otf') format('opentype'),
}
@font-face{
    font-family: 'roobert-semibold';
    src: url('./fontlist/Roobert/RoobertTRIAL-SemiBold-BF67243fd54213d.otf') format('truetype'),
}
@font-face{
    font-family: 'roobert-regular';
    src: url('./fontlist/Roobert/RoobertTRIAL-Regular-BF67243fd53fdf2.otf') format('truetype'),
}


